import React from "react";
import { isMobile } from "react-device-detect";
import ImageGallery from "react-image-gallery";

const Gallery = (props) => {
  return (
    <ImageGallery
      showPlayButton={false}
      items={props.images}
      lazyload={true}
      showFullscreenButton={!isMobile}
      useBrowserFullscreen={false}
    />
  );
};

export default Gallery;
