import React, { useState } from "react";
import Icon1 from "./images/icon1.svg";
import Icon2 from "./images/icon2.svg";
import Icon3 from "./images/icon3.svg";
import Icon4 from "./images/icon4.svg";
import Popup from "reactjs-popup";

const PROJECT = [
  {
    id: 1,
    icon: Icon1,
    title: "Tư vấn chuyên môn, sáng tạo đa dạng",
    desc: `KTS/NTK trao đổi gặp gỡ cùng khách hàng 
      Phương án ẩn tượng, bản vẽ trực quan và báo giá rõ ràng, đầy đủ, chi tiết`,
  },
  {
    id: 2,
    icon: Icon2,
    title: "Tối ưu hóa chi phí, hạn chế rủi ro phát sinh",
    desc: `Chi phí tốt nhất mang đến giá trị phù hợp với nhu cầu mong muốn của khách hàng
      Hạn chế rủi ro đầu tư và phát sinh ngoài ý muốn cho khách hàng`,
  },
  {
    id: 3,
    icon: Icon3,
    title: "Kiến trúc bản địa hóa \n (Location Design)",
    desc: `Sinh hoạt, lối sống, tiện ích, nhu cầu, cảm xúc, vùng miền,v.v..
      Hiểu nét bản địa hóa để tạo dựng nên tính cá nhân hóa của khách hàng`,
  },
  {
    id: 4,
    icon: Icon4,
    title: "Trao niềm tin, mang sự hài lòng",
    desc: `Bảo hành 2 năm, đồng hành cùng bạn suốt quá trình sử dụng.
      Bảo trì 4 năm, cần chúng tôi có, khó có chúng tôi lo`,
  },
];

const Project = (props) => {
  const [font1, setFont1] = useState(24);
  const [font2, setFont2] = useState(20);
  const [font3, setFont3] = useState(14);
  const renderPopup = () => (
    <Popup
        trigger={
          <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14 }}>
            Chỉnh fontsize
          </button>}
        modal
      >
        <div>
          <label>
            Tiêu đề:
            <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
          </label>
          <br />
          <label>
            Tiêu đề phụ:
            <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
          </label>
          <br />
          <label>
            Nội dung:
            <input className="border m-2" name="more" value={font3} onChange={e => setFont3(e.target.value)} />
          </label>
        </div>
      </Popup>
  )
  const renderProject = () => {
    return PROJECT.map((project) => {
      return (
        <div key={project.id} className="w-full md:w-[100%] mx-auto">
          <img src={project.icon} className="mb-5 mx-auto md:mx-0 w-[30px] h-[30px]" alt="..." />
          <p className="text-primary text-2xl mb-4 text-center md:text-left text-[18px] md:text-[20px]">
            {project.title}
          </p>
          <p className="text-primary text-[#616161] whitespace-pre-line text-center md:text-left sub-text-size w-[80%] md:w-full mx-auto">
            {project.desc}
          </p>
        </div>
      );
    });
  };
  return (
    <div className="relative container mx-auto h-auto md:min-h-screen flex flex-col items-center justify-center gap-[20px] md:gap-[5vw] py-[30px]">
      <h1 className="text-black title-3 text-center text-[24px] md:text-[40px]">
        Điều khiến chúng tôi khác biệt?
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[40px] md:gap-[70px] px-4 md:px-10">
        {renderProject()}
      </div>
      <div className="md:h-[10vh]" />
      {/* {renderPopup()} */}
    </div>
  );
};

export default Project;
