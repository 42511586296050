import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import { ROUTES } from "src/router/routes";

const ContactUs = (props) => {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col md:flex-row my-[25%] md:mt-[15%] md:mb-0">
      <div
        className="w-full md:w-1/2"
        style={{
          height: isMobile ? 414 : 750,
          background: isMobile ? `url(${require('./images/contact_us_mobile.jpg')})` : `url(${require('./images/contact_us_desktop.jpg')})`,
          backgroundAttachment: isMobile ? "unset" : "fixed",
          backgroundSize: "cover",
          backgroundPositionX: "50%",
          backgroundPositionY: "50%",
        }}
      ></div>
      <div className="w-full md:w-1/2 pt-[20px] md:pt-0 flex gap-[20px] flex-col justify-center padding-x-box md:pl-[6%] pr-0 md:pr-6">
        <h3 className="text-primary w-[80%] text-[24px] md:text-[30px]">
          Bạn đã sẵn sàng cá nhân hoá không gian sống?
        </h3>
        <ScrollAnimation animateIn="animate-bounceInRight">
          <div className="h-[1px] w-[150px] md:w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary w-full md:w-2/3 sub-text-size text-[14px]">
          Mong muốn và quyết định nằm ở bạn. Lắng nghe và hiện thực hoá là chúng
          tôi.
        </p>
        <Button onClick={() => navigate(ROUTES.Main.Register)}>Liên hệ</Button>
      </div>
    </div>
  );
};

export default ContactUs;
