import React from "react";
import ScrollAnimation from "src/components/ScrollAnimation";

function BottomContact() {
  return (
    <div className="flex flex-col md:flex-row my-[30px] md:my-[60px] md:mb-0 md:h-screen">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.981686781578!2d106.7061649387582!3d10.73730633260226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f9bb433f883%3A0x5c833e6ed77ffc61!2zNDEgxJDGsOG7nW5nIHPhu5EgNTIsIFAuIFBow7ogVGh14bqtbiwgUXXhuq1uIDcsIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1727177638145!5m2!1svi!2s"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="w-full md:w-1/2 md:h-full"
      ></iframe>
      <div className="w-full md:w-1/2 pt-[20px] md:pt-0 flex gap-[20px] mb-5 flex-col justify-center padding-x-box md:pl-[6%] pr-0 md:pr-6">
        <h1 className="uppercase text-[24px] md:text-[40px] text-black">VĂN PHÒNG HIDIARCH</h1>
        <ScrollAnimation animateIn="animate-bounceInRight">
          <div className="h-[1px] w-[150px] md:w-[200px] bg-black" />
        </ScrollAnimation>
        <div>
          <p className="text-primary sub-text-size text-[14px]">Tọa lạc tại địa chỉ:</p>
          <p className="text-primary sub-text-size text-[14px] w-[80%]">41 Đường số 52, KDC Tân Quy Đông, P. Tân Phong, Q7, TP HCM</p>
        </div>
      </div>
    </div>
  );
}

export default BottomContact;
