import React, { useEffect, useRef, useState } from 'react'
import TopContact from './TopContact/TopContact'
import CenterContact from './CenterContact/CenterContact'
import BottomContact from './BottomContact/BottomContact'

function ContactPage({ shouldScrollToForm }) {
  console.log(shouldScrollToForm)
  const formRef = useRef();
  useEffect(() => {
    if (shouldScrollToForm) {
      const postion = formRef.current.getBoundingClientRect();
      window.scrollTo({ top: postion.top, behavior: 'smooth' });
    }
  }, [])

  return (
    <div>
      <TopContact />
      <div ref={formRef}>
        <CenterContact />
      </div>
      <BottomContact />
    </div>
  )
}

export default ContactPage