import React from "react";
import ScrollAnimation from "src/components/ScrollAnimation";

const AboutUsItem = ({ item, index, onClick }) => {
  const { img, title, subTitle, desc } = item ?? {};
  const flexClassName = index % 2 !== 0 ? "flex-row" : "flex-row-reverse";
  return (
    <div className={`h-auto md:max-h-[100vh] md:min-h-[50vw] mt-[${index === 0 ? 0 : 30}px] md:mt-auto flex flex-col md:${flexClassName} mx-auto relative mb-20`}>
      <div className={`md:w-3/5 flex justify-center items-center`} onClick={onClick}>
        <div className="relative">
          <img
            src={img}
            id="img"
            className="w-auto h-auto md:h-[100vh] object-cover"
            alt="..."
          />
          <div className="bg-white w-full h-full absolute top-0 bg-opacity-15"></div>
        </div>
      </div>

      <div className="padding-x-box gap-[20px] mb-20 md:w-2/5 mt-5 md:mt-6 flex-col flex gap-[20px]">
        <h3 className="text-primary title-2 md:mt-[10vh] md:text-[50px] text-[30px] whitespace-pre-line" style={{ lineHeight: 1.25 }} onClick={onClick}>
          <ScrollAnimation animateIn="animation-fadeIn">
            {title}
          </ScrollAnimation>
        </h3>
        <ScrollAnimation animateIn=" md:mt-0 animate-slowBounceInLeft">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <h3 className="text-primary text-[24px] md:text-[30px] uppercase" style={{ lineHeight: 1.25 }}>
          {subTitle}
        </h3>
        <p className="text-primary sub-text-size text-[14px] text-justify whitespace-pre-line">
          {desc}
        </p>
      </div>

    </div>
  );
};

export default AboutUsItem;
