import React from "react";
import ScrollAnimation from "src/components/ScrollAnimation";

const ServiceItem = ({item, index, onClick}) => {
  const {img, title, desc} = item ?? {};
  const flexClassName = index % 2 === 0 ? "flex-row" : "flex-row-reverse";
  return (
    <div className={`h-auto md:max-h-[100vh] md:min-h-[50vw] mt-[${index === 0 ? 0 : 30}px] md:mt-auto flex flex-col md:${flexClassName} mx-auto relative mb-20`}>
      <div className={`md:w-3/5 flex justify-center items-center`} onClick={onClick}>
        <div className="group cursor-pointer relative">
          <img
            src={img}
            id="img"
            className="w-auto h-auto md:h-[100vh] object-cover"
            alt="..."
          />
          <div className="opacity-0 group-hover:opacity-50 absolute bg-white top-0 bottom-0 left-0 right-0 boxHover flex justify-center items-center">
            <p className="text-primary uppercase mb-2 text-[18px] leading-5">
              Xem dự án
            </p>
          </div>
          <div className="bg-white w-full h-full absolute top-0 bg-opacity-15"></div>
        </div>
      </div>

      <div className="padding-x-box gap-[20px] mb-20 md:w-2/5 mt-5 md:mt-6 flex-col flex gap-[20px] justify-center">
        <h3 className="text-primary title-2 md:mt-[70px] md:text-[50px] text-[30px] cursor-pointer" style={{ lineHeight: 1.25 }} onClick={onClick}>
          <ScrollAnimation animateIn="animation-fadeIn">
            {title}
          </ScrollAnimation>
        </h3>
        <ScrollAnimation animateIn=" md:mt-0 animate-slowBounceInLeft">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary sub-text-size text-[14px] text-justify">
          {desc}
        </p>
      </div>
      
    </div>
  );
};

export default ServiceItem;
