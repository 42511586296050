import React from "react";
import ScrollAnimation from "src/components/ScrollAnimation";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import CarouselComponent from "src/pages/main/V2/HomePage/components/Client/components/Carousel";
import Carousel1 from "./images/HomePage-Testimonials_1.jpg";
import Carousel2 from "./images/HomePage-Testimonials_2.jpg";
import Carousel3 from "./images/HomePage-Testimonials_3.jpg";
import Carousel4 from "./images/HomePage-Testimonials_4.jpg";
import Carousel5 from "./images/HomePage-Testimonials_5.jpg";
import Carousel6 from "./images/HomePage-Testimonials_6.jpg";
import Carousel7 from "./images/HomePage-Testimonials_7.jpg";

const CAROUSELS = [
  {
    id: 1,
    image: Carousel1,
    description:
      "“Không gì tuyệt vời hơn khi KTS của Hidiarch giải đáp và đồng hành cùng tổ ấm đầu tiên của 2 vợ chồng từ bản vẽ đến hoàn thiện một cách thật yên tâm nhất.”",
    name: "LÝ HUỲNH MINH TRUNG",
    address: "NHÀ PHỐ NHÀ BÈ – QUẬN 7",
  },
  {
    id: 2,
    image: Carousel2,
    description:
      "“Ấp ủ khởi nghiệm nên việc lựa chọn một đơn vị thiết kế đúng ý, thi công đúng chuẩn là ưu tiên hàng đầu. Thật may mắn vì Hidiarch đã cùng tôi vẽ lên ước mơ và hiện thực hóa nó.. mãi iu Hidiarch.”",
    name: "TRẦN THỊ THANH",
  },
  {
    id: 3,
    image: Carousel3,
    description:
      "“Hidiarch đã thể hiện được kinh nghiệm cùng bãn lĩnh của đội ngũ KTS đã mang đến một thiết kế thật ấn tượng vừa đáp ứng công năng vừa hài hòa Phong Thủy.”",
    name: "NGUYỄN HOÀNG ANH DUY",
  },
  {
    id: 4,
    image: Carousel4,
    description:
      "“Mong muốn hình thành nên một căn hộ vừa đơn giản, vừa dễ thương, vừa phù hợp với chi phí đề ra đã được đội ngủ Hidiarch lắng nghe và nỗ lực hết mình.“",
    name: "TRẦN THỊ TRÚC PHƯƠNG",
  },
  {
    id: 5,
    image: Carousel5,
    description:
      "“Cảm ơn Hidiarch vì đã thay đổi biến hóa ngôi nhà từ cũ kỉ trở thành một không gian sống ngoài sức tưởng tượng của bản thân.. không gì hạnh phúc hơn.”",
    name: "TRẦN THỊ ÁI THỦY",
  },
  {
    id: 6,
    image: Carousel6,
    description:
      "“Thiết kế đúng nhu cầu sở thích của tôi, thi công chuyên nghiệp. Đánh giá đơn giản là tôi hài lòng ở các bạn.”",
    name: "TRẦN THỊ TRANG",
  },
  {
    id: 7,
    image: Carousel7,
    description:
      "“Hidiarch giúp tôi có những giải pháp tối ưu nhất với không gian nhỏ của ngôi nhà cùng những trải nghiệm thực tế thật sự hài lòng từ công năng đến thẩm mỹ.”",
    name: "NGUYỄN ANH TÂM",
  },
];

const Client = () => {
  const isMobileOrTablet = useMobileOrTablet();
  return (
    <div className="relative bg-heroBg flex flex-col md:flex-row my-[25%] md:mt-[15%] md:mb-0 py-[30px] gap-[20px]" style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      minHeight: isMobileOrTablet ? 'auto' : '100vh',
    }}>
      <h2 className="text-primary md:text-[40px] text-[30px] mx-[20px]">
        <ScrollAnimation animateIn="animation-fadeIn text-center">
          Khách hàng nói gì về Hidiarch?
        </ScrollAnimation>
      </h2>
      <div className="relative">
        <CarouselComponent data={CAROUSELS} />
      </div>
    </div>
  );
};

export default Client;
