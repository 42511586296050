import React from "react";
import OclockIcon from"../images/oclock.svg"
import EmailIcon from"../images/email.svg"
import LocationIcon from"../images/location.svg"
import ContactBanner from "../images/banner.png"
import { open, openInNewTab } from "src/utils";

function TopContact() {
  const onClick = (type) => () => {
    switch (type) {
      case "phone":
        open("tel://0798897288");
        break;

      case "facebook":
        openInNewTab("https://www.facebook.com/profile.php?id=61565159765953");
        break;

      case "mail":
        open("mailto:hidiarch.studio@gmail.com");
        break;

      case "location":
        openInNewTab("https://maps.app.goo.gl/4cDemrWCk7uQBBy99");
        break;

      default:
        break;
    }
  };
  return (
    <div className="flex flex-col-reverse md:flex-row mb-4 md:ml-[8%] md:h-[calc(100vh-56px)]">
      <div className=" grid gap-[65px] md:w-2/5 my-[5%] md:h-[50vh] ml-[8%] md:ml-0">
        <div className=" grid gap-2">
          <div>
            <h2 className="uppercase text-[24px] md:text-[40px] text-black">
              Hidiarch
            </h2>
            <h2 className="uppercase text-[24px] md:text-[40px] text-black">
              liên hệ & trao đổi
            </h2>
          </div>
          <div className="border-t-[1px] w-[30%]"></div>
          <p className="text-primary w-full md:w-2/3 sub-text-size text-[14px]">
            Liên hệ ngay để mỗi góc nhỏ trong không gian sống đều mang dấu ấn
            riêng của bạn
          </p>
        </div>
        <div className="grid gap-[35px]">
          <div className="flex">
            <img src={OclockIcon} alt="..." className="w-[45px] h-[45px] mr-[15px]"/>
            <div>
              <h3 className="text-primary my-[5px] text-2xl text-left text-[18px] md:text-[20px] font-semibold">Giờ làm việc</h3>
              <div>
                <p className="text-primary text-[#616161] whitespace-pre-line text-left sub-text-size w-[80%] md:w-full">Thứ 2 - Thứ 6: 9H00 đến 18H00</p>
                <p className="text-primary text-[#616161] whitespace-pre-line text-left sub-text-size w-[80%] md:w-full">Thứ 7: 9H00 đến 14H00</p>
              </div>
            </div>
          </div>
          <div className="flex">
          <img src={EmailIcon} alt="..." className="w-[45px] h-[45px] mr-[15px]"/>
            <div>
              <h3 className="text-primary mb-[5px] text-2xl text-left text-[18px] md:text-[20px] font-semibold">Liên hệ</h3>
              <div>
                <p className="text-primary text-[#616161] whitespace-pre-line text-left sub-text-size w-[80%] md:w-full cursor-pointer" onClick={onClick('phone')}>Phone: 0798 897 288</p>
                <p className="text-primary text-[#616161] whitespace-pre-line text-left sub-text-size w-[80%] md:w-full cursor-pointer" onClick={onClick('mail')}>Email: hidiarch.studio@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="flex">
          <img src={LocationIcon} alt="..." className="w-[45px] h-[45px] mr-[15px]"/>
            <div>
              <h3 className="text-primary mb-[5px] text-2xl text-left text-[18px] md:text-[20px] font-semibold">Tư vấn trực tiếp</h3>
              <div>
                <p className="text-primary text-[#616161] whitespace-pre-line text-left sub-text-size w-[80%] cursor-pointer" onClick={onClick('location')}>Địa chỉ: 41 Đường số 52, KDC Tân Quy Đông, P. Tân Phong, Q7, TP HCM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-3/5">
      <img  src={ContactBanner} alt="..." className="w-full aspect-[1.8] cursor-pointer" onClick={onClick('facebook')} />
      </div>
    </div>
  );
}

export default TopContact;
// aspect-[1/1]