import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import useFirebase from "src/hooks/useFirebase";
import { useLoading } from "src/hooks/useLoading";
import { isEmpty, validateEmail } from "src/utils";

function CenterContact() {
  const { showLoading, hideLoading } = useLoading();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [content, setContent] = useState('');
  const { addMail } = useFirebase();

  const onSubmit = async () => {
    if (isEmpty(name)) {
      toast.error('TÊN không được để trống');
      return;
    }
    if (email && !validateEmail(email)) {
      toast.error('EMAIL không hợp lệ');
      return;
    }
    if (isEmpty(phoneNumber)) {
      toast.error('SỐ ĐIỆN THOẠI không được để trống');
      return;
    }
    if (isEmpty(content)) {
      toast.error('NỘI DUNG TƯ VẤN không được để trống');
      return;
    }

    showLoading();
    await addMail({ name, email, phoneNumber, content, status: 'unread', timestamp: Date.now() },
      () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
      () => { toast.success('Chúng tôi sẽ liên hệ với bạn sớm') }
    );
    hideLoading();
    setName('');
    setEmail('');
    setPhoneNumber('');
    setContent('');
  }

  return (
    <div className="h-auto bg-heroBg py-[30px] padding-x-box md:h-screen">
      <div className="h-full flex flex-col items-center justify-center">
        <div className="grid gap-[10px]">
          <h1 className="text-center uppercase text-[24px] md:text-[40px]">
            <ScrollAnimation animateIn="animation-fadeIn">
              Đăng ký tư vấn
            </ScrollAnimation>
          </h1>
          <div className=" border-t-[1px] w-[25%] mx-auto"></div>
          <p className="mx-auto text-primary text-[#616161] whitespace-pre-line text-center sub-text-size w-[100%]">
            Bạn đang quan tâm đến dịch vụ nào của Hidiarch Studio? <br />Để lại thông tin dưới đây, Kiến trúc sư sẽ nhanh chóng liên hệ với bạn.
          </p>
        </div>
        <div className="flex md:flex-row justify-center flex-wrap md:flex-nowrap my-[20px] md:my-[40px]"></div>
        <form className="space-y-6 w-[80%] mx-auto p-4">
          <input
            type="text"
            name="firstName"
            placeholder="Họ và tên*"
            className="placeholder-200  border-gray-300 p-2 w-full bg-heroBg border-b-[1px] focus:outline-none focus:ring-0 cursor-default"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <div className="flex flex-col md:flex-row md:space-x-4">
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Số điện thoại*"
              className="placeholder-200  border-gray-300 p-2 w-full bg-heroBg border-b-[1px] focus:outline-none focus:ring-0 cursor-default"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="placeholder-200  border-gray-300 p-2 w-full bg-heroBg border-b-[1px] focus:outline-none focus:ring-0 cursor-default mt-4 md:mt-0"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <textarea
            style={{ resize: "none" }}
            name="message"
            placeholder="Nội dung cần tư vấn? *"
            className="placeholder-200  border-gray-300 p-2 w-full h-32 bg-heroBg border-b-[1px] focus:outline-none focus:ring-0 cursor-default"
            value={content}
            onChange={e => setContent(e.target.value)}
          />
          <div className="text-center">
            <Button onClick={onSubmit}>GỬI</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CenterContact;
