import React from "react";
import IMAGE1 from "./images/homepage_service1.jpg";
import IMAGE2 from "./images/homepage_service2.jpg";
import IMAGE3 from "./images/homepage_service3.jpg";
import IMAGE4 from "./images/homepage_service4.jpg";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import 'reactjs-popup/dist/index.css';
import { useApp } from "src/hooks/useApp";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";

const SERVICES = [
  {
    id: 1,
    title: "Kiến trúc xây dựng",
    desc: "This text briefly introduces your services to visitors.",
    path: ROUTES.Main.ConstructionServices,
    img: IMAGE1,
  },
  {
    id: 2,
    title: "Thiết kế nội thất",
    desc: "This text briefly introduces your services to visitors.",
    path: ROUTES.Main.InteriorServices,
    img: IMAGE2,
  },
  {
    id: 3,
    title: "Thiết kế cải tạo",
    desc: "This text briefly introduces your services to visitors.",
    path: ROUTES.Main.RenovationServices,
    img: IMAGE3,
  },
  {
    id: 4,
    title: "Thiết kế khác",
    desc: "This text briefly introduces your services to visitors.",
    path: ROUTES.Main.OtherServices,
    img: IMAGE4,
  },
];

const Service = () => {
  const navigate = useNavigate();
  const renderServices = () => {
    return SERVICES.map((service) => (
      <div
        onClick={() => navigate(service.path)}
        key={service.id}
        className="group cursor-pointer h-[180px] w-[180px] md:w-[20vw] md:h-[20vw] max-w-[350px] max-h-[350px] m-[6px] md:m-[16px] relative"
      >
        <div>
          <img src={service.img} className="h-[180px] w-[180px] max-w-[350px] max-h-[350px] md:w-[20vw] md:h-[20vw] ra object-cover" alt="" />
        </div>
        <div className="opacity-0 group-hover:opacity-70 w-full absolute bg-white top-0 h-full flex flex-col justify-center p-[8px] md:p-[35px] boxHover text-center py-5">
          <p className="text-primary uppercase mb-2 leading-5 text-[14px]">
            {service.title}
          </p>
          {/* <p className="text-primary sub-text-size">{service.desc}</p> */}
        </div>
      </div>
    ));
  };
  return (
    <div className="relative h-auto md:h-screen bg-heroBg py-[30px] padding-x-box">
      <div className="container h-full m-auto flex flex-col md:justify-evenly items-center">
        <h1 className="text-center m-[8px] mx-[10vw] uppercase text-[24px] md:text-[40px]">
          <ScrollAnimation animateIn="animation-fadeIn">
            Lĩnh vực hoạt động Hidiarch
          </ScrollAnimation>
        </h1>
        <div className="flex md:flex-row justify-center flex-wrap md:flex-nowrap my-[20px]">
          {renderServices()}
        </div>
        <Button onClick={() => navigate(ROUTES.Main.AllServices)}>Tìm hiểu thêm</Button>
      </div>
    </div>
  );
};

export default Service;
