import React, { useEffect, useState } from "react";
import ScrollAnimation from "src/components/ScrollAnimation";
import GalleryItem from "./shared/ProjectItem";
import ContactUs from "./shared/ContactUs";
import { useLoading } from "src/hooks/useLoading";
import useFirebase from "src/hooks/useFirebase";

export default function Projects({ title = "DỰ ÁN THỰC TẾ", category = "all" }) {
  const [projects, setProjects] = useState([]);
  const loading = useLoading();
  const { getProjects } = useFirebase();

  useEffect(() => {
    async function fetchData() {
      localStorage.setItem('@category', category);
      loading.showLoading();
      const data = await getProjects(category)
      setProjects(data.filter(el => el.hidden !== true));
      loading.hideLoading()
    }
    category && fetchData()
  }, [category])

  return (
    <div>
      {/* text section */}
      <div className="flex flex-col items-center m-[10px] md:m-[20px] my-[30px]">
        <div className="flex flex-col gap-[10px] md:gap-[10px]">
          <ScrollAnimation animateIn="animation-fadeIn">
            <h3 className="uppercase text-primary text-[48px] md:text-[60px]" style={{lineHeight: 1.25}}>
              {title}
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="mt-0 animate-bounceInRight">
            <div className="h-[1px] w-[200px] bg-black" />
          </ScrollAnimation>
          <p className="text-primary w-full sub-text-size" style={{ fontSize: parseInt(14) }}>
            Hidiarch ra đời nhằm mang đến những giá trị thẩm mỹ, tối ưu công năng
            cho không gian sống cũng như lan toả niềm đam mê, nhiệt huyết của tuổi
            trẻ, góp phần phát triển và vươn xa hơn cho nền kiến trúc Việt Nam.
          </p>
        </div>
      </div>
      {/* images section */}
      <div className="px-2 md:px-0 w-full md:w-[80%] mx-auto h-auto flex flex-wrap gap-[5px] md:gap-[10px] justify-center">
        {projects.map((el, index) => <GalleryItem item={el} key={index} />)}
      </div>
      {/* contact us section*/}
      <ContactUs />
    </div>
  );
}
