import React from "react";
import IMAGE1 from "../images/IMG_2352.jpg";
import IMAGE2 from "../images/IMG_2395.jpg";
import IMAGE3 from "../images/IMG_2525.jpg";
import IMAGE4 from "../images/IMG_2541.jpg";
import IMAGE5 from "../images/IMG_2592.jpg";
import IMAGE6 from "../images/IMG_2596.jpg";
import ScrollAnimation from "src/components/ScrollAnimation";
import 'reactjs-popup/dist/index.css';

const LIST = [
  {
    id: 1,
    title: "Kiến trúc xây dựng",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE1,
  },
  {
    id: 2,
    title: "Thiết kế nội thất",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE2,
  },
  {
    id: 3,
    title: "Thiết kế cải tạo",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE3,
  },
  {
    id: 4,
    title: "Thiết kế khác",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE4,
  },
  {
    id: 5,
    title: "Thiết kế cải tạo",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE5,
  },
  {
    id: 6,
    title: "Thiết kế khác",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE6,
  },
];

const Workspace = (props) => {
  const renderList = () => {
    return LIST.map((service) => (
      <div
        key={service.id}
        className="group cursor-pointer h-[180px] w-[180px] md:w-[18vw] md:h-[18vw] max-w-[400px] max-h-[400px] m-[6px] md:m-[16px] relative"
      >
        <div>
          <img src={service.img} className="h-[180px] w-[180px] max-w-[400px] max-h-[400px] md:w-[18vw] md:h-[18vw] ra object-cover" alt="" />
        </div>
        {/* <div className="opacity-0 group-hover:opacity-70 w-full absolute bg-white top-0 h-full flex flex-col justify-center p-[8px] md:p-[35px] boxHover text-center py-5">
          <p className="text-primary uppercase mb-2 leading-5 text-[14px]">
            {service.title}
          </p>
        </div> */}
      </div>
    ));
  };
  return (
    <div className="relative h-auto md:h-screen bg-heroBg py-[30px] padding-x-box">
      <div className="container h-full m-auto flex flex-col md:justify-evenly items-center">
        <h1 className="text-center m-[8px] mx-[10vw] uppercase text-[24px] md:text-[40px]">
          <ScrollAnimation animateIn="animation-fadeIn">
            MỘT SỐ HÌNH ẢNH VỀ ĐỘI NGŨ HIDIARCH
          </ScrollAnimation>
        </h1>
        <div className="flex md:flex-row justify-center flex-wrap my-[20px] md:w-[70vw]">
          {renderList()}
        </div>
      </div>
    </div>
  );
};

export default Workspace;
