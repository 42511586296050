import React, { Fragment, useEffect, useState } from "react";
import cx from "clsx";
import MenuIcon from "src/assets/images/menu.svg";
import CloseIcon from "src/assets/images/close.svg";
import ExpandIcon from "src/assets/images/expandIcon.svg";
import Logo from "../../../../assets/images/LOGO-DOC-1.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { open } from "src/utils";
import { ROUTES } from "src/router/routes";
import useMobileOrTablet from "src/hooks/useMediaQuery";

const NavbarItems = [
  {
    title: "Trang chủ",
    key: "home",
    path: ROUTES.Main.Home,
    children: [],
  },
  {
    title: "Về chúng tôi",
    key: "about-us",
    path: ROUTES.Main.AboutUs,
    children: [],
  },
  {
    title: "Dịch Vụ",
    key: "services",
    path: ROUTES.Main.AllServices,
    children: [
      {
        title: "Kiến trúc xây dựng",
        key: "service-1",
        path: ROUTES.Main.ConstructionServices,
      },
      {
        title: "Thiết kế nội thất",
        key: "service-2",
        path: ROUTES.Main.InteriorServices,
      },
      {
        title: "Thiết kế cải tạo",
        key: "service-3",
        path: ROUTES.Main.RenovationServices,
      },
      {
        title: "Thiết kế khác",
        key: "service-4",
        path: ROUTES.Main.OtherServices,
      },
    ],
  },
  {
    title: "Dự Án",
    key: "projects",
    path: ROUTES.Main.Projects,
    children: [],
  },
  {
    title: "Hỗ trợ",
    key: "contact",
    path: ROUTES.Main.ContactUs,
    children: [
      {
        title: "Liên hệ",
        key: "contact-about",
        path: ROUTES.Main.ContactUs,
      },
      {
        title: "Đăng ký tư vấn",
        key: "contact-register",
        path: ROUTES.Main.Register,
      },
    ],
  },
];

let interval;

const Navbar = (props) => {
  const location = useLocation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [showActionFadeout, setShowActionFadeout] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);
  const [activeTabs, setActiveTabs] = useState([]);
  const isMobileOrTablet = useMobileOrTablet();
  const pathname = location.pathname;

  useEffect(() => {
    if (isOpenMenu) {
      const activeTab = NavbarItems.find((item) => {
        const paths = item.children.map((item) => item.path);
        return [...paths, item.path].includes(pathname);
      });
      if (activeTab) {
        setActiveTabs([activeTab.key]);
      }
    }
  }, [isOpenMenu]);

  useEffect(() => {
    if (percent >= 99) {
      clearInterval(interval);
    }
  }, [percent]);

  const redirect = (url) => {
    navigate(url);
    closeMenu();
  };

  const handleNavbarItemClick = (tab) => {
    setPercent(0);
    setActiveTab(tab);
    clearInterval(interval);
    interval = setInterval(function () {
      setPercent((percent) => percent + 1);
    }, 1);
    if (activeTabs.includes(tab)) {
      setActiveTabs(activeTabs.filter((activeTab) => activeTab !== tab));
    } else {
      setActiveTabs((activeTabs) => [...activeTabs, tab]);
    }
  };

  const closeMenu = () => {
    setShowActionFadeout(true);
    setTimeout(() => {
      setIsOpenMenu(false);
      setShowActionFadeout(false);
      setActiveTabs([]);
    }, 400);
  };

  const openHomePage = () => {
    navigate(ROUTES.Main.Home);
  };

  const renderMobileAvatar = () => {
    return (
      <div className="flex items-center gap-2 uppercase">
        <img
          src={Logo}
          onClick={openHomePage}
          className="w-[30px] h-[30px] border rounded-[15px] cursor-pointer"
          alt=""
        />
        <h1 className="text-black text-[12px] font-bold md:text-left">
          Hidiarch Studio
        </h1>
      </div>
    );
  };

  // mobile
  if (isMobileOrTablet) {
    return (
      <div className="relative border-b-2 border-[#141414] py-[8px] px-[16px]">
        <div className="container mx-auto flex justify-between items-center ">
          <div>{renderMobileAvatar()}</div>
          <div className="flex flex-row">
            <div className="text-[12px]">
              <button
                onClick={() => open("tel://0798897288")}
                className="border rounded-[5px] mr-[20px] uppercase bg-black h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white hover:text-black"
              >
                HOTLINE
              </button>
            </div>
            <img
              alt=""
              height={22}
              width={22}
              src={MenuIcon}
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            />
          </div>
        </div>
        {isOpenMenu && (
          <div
            className={cx(
              "animation-fill shadow-2xl border-r-[1px] z-[9999999999999] absolute h-screen top-0 left-0 border-1 bg-white w-[320px] z-10 border-b-2 border-[#141414]",
              {
                "animate-slideInLeft": isOpenMenu,
                "animate-slideOutLeft ": showActionFadeout,
              }
            )}
          >
            <div className="p-[15px] mb-2 flex justify-between">
              {renderMobileAvatar()}
              <img
                height={20}
                width={20}
                src={CloseIcon}
                alt="close icon"
                onClick={closeMenu}
              />
            </div>
            {NavbarItems.map((item) => {
              const _isActive = activeTabs.includes(item.key);
              const sizeOfChildren = item.children.length;
              const heightOfMenuItems = sizeOfChildren * 52;
              const currentHeight = activeTabs.includes(item.key)
                ? (percent / 100) * heightOfMenuItems
                : heightOfMenuItems - (percent / 100) * heightOfMenuItems;
              return (
                <Fragment key={item.key}>
                  <div
                    className={cx(
                      "flex justify-between text-sm cursor-pointer py-[12px] px-[20px]"
                    )}
                    onClick={() =>
                      sizeOfChildren
                        ? handleNavbarItemClick(item.key)
                        : redirect(item.path)
                    }
                  >
                    <p className="text-sm text-black">{item.title}</p>
                    {!!sizeOfChildren && (
                      <img
                        alt=""
                        src={ExpandIcon}
                        className={cx(
                          "transition-all duration-300 w-[16px] h-[16px]",
                          {
                            "rotate-180": activeTabs.includes(item.key),
                          }
                        )}
                      />
                    )}
                  </div>
                  {!!sizeOfChildren && (
                    <div
                      style={{
                        height:
                          activeTab === item.key
                            ? currentHeight
                            : _isActive
                            ? heightOfMenuItems
                            : 0,
                      }}
                      className={cx(
                        "px-[40px] transition-transform overflow-hidden"
                      )}
                    >
                      {item.children.map((item) => {
                        return (
                          <div
                            onClick={() => redirect(item.path)}
                            key={item.key}
                            className="py-4 text-sm text-primary hover:text-black"
                          >
                            {item.title}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  // desktop
  return (
    <div className="h-[56px] flex justify-between items-center px-[10px] border-b-[3px] border-black bg-[#ffffff]">
      <div className="flex items-center gap-2 uppercase w-3/12 ">
        <img
          src={Logo}
          onClick={openHomePage}
          className="w-[30px] h-[30px] border rounded-[15px] cursor-pointer"
          alt=""
        />
        <h1 className="text-black text-[12px] font-bold md:text-left">
          Hidiarch Studio
        </h1>
      </div>
      <div className="hidden md:flex flex-wrap justify-end md:justify-center lg:justify-end gap-[40px] md:w-full lg:w-9/12 mr-[50px]">
        {NavbarItems.map((item, index) => {
          const lastItem = index === NavbarItems.length - 1;
          return (
            <div className="relative group py-8 z-10" key={index}>
              <div className="group cursor-pointer">
                <div
                  key={item.key}
                  className={cx(
                    "text-[14px] text-[#757575] group-hover:text-black"
                  )}
                  onClick={() => navigate(item.path)}
                >
                  {item.title}
                </div>
                {(pathname === item.path ||
                  item.children.find((item) => item.path == pathname)) && (
                  <div className="border-t-[1px]" />
                )}
                <div className="opacity-0 border-t-[1px] invisible transition-all duration-300 ease-in group-hover:opacity-100 group-hover:visible"></div>
              </div>
              {!!item.children.length && (
                <div
                  className={cx(
                    "hidden absolute z-50 group-hover:block w-auto cursor-pointer bg-white shadow-md translate-y-[22px] py-4 px-2 md:w-[180px]",
                    {
                      "translate-x-[calc(-100%+50px)]": lastItem,
                      "md:w-[160px]": lastItem,
                    }
                  )}
                >
                  {item.children.map((item) => {
                    return (
                      <div
                        key={item.key}
                        className="w-100 py-3 text-[14px] text-[#757575] px-5 hover:animate-pulsate hover:animation-fill hover:text-black transition-all"
                        onClick={() => navigate(item.path)}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="text-[12px]">
        <button
          onClick={() => open("tel://0798897288")}
          className="border rounded-[5px] uppercase bg-black h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white hover:text-black"
        >
          HOTLINE
        </button>
      </div>
    </div>
  );
};

export default Navbar;
