import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import "./styles.css";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <>
      <div
        className="absolute cursor-pointer top-1/2 -translate-y-1/2 right-0 w-[43px] h-[43px] rounded-full bg-[#00000080] hover:bg-[#000c] flex justify-center items-center text-white text-[20px] md:right-[-50px]"
        onClick={() => next()}
      >
        <FontAwesomeIconAnimated icon={faChevronRight} />
      </div>
      <div
        className="fa-solid fa-chevron-left absolute cursor-pointer top-1/2 -translate-y-1/2 left-0 w-[43px] h-[43px] rounded-full bg-[#00000080] hover:bg-[#000c] flex justify-center items-center text-white text-[20px] md:left-[-50px]"
        onClick={() => previous()}
      >
        <FontAwesomeIconAnimated icon={faChevronLeft} />
      </div>
    </>
  );
};

const CarouselComponent = (props) => {
  const isMobileOrTablet = useMobileOrTablet();
  const { data = [] } = props;
  return (
    <Carousel
      additionalTransfrom={0}
      autoPlay
      autoPlaySpeed={isMobileOrTablet ? 3000 : 5000}
      centerMode={false}
      className="container mx-auto"
      containerClass="w-[100vw] md:w-[50vw]"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={true}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 768,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: {
            max: 768,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={isMobileOrTablet ? 1 : 3}
      swipeable
    >
      {data.map((carousel) => (
        <CarouselItem
          key={carousel.id}
          {...carousel}
        />
      ))}
    </Carousel>
  );
};

const CarouselItem = ({
  id,
  image,
  description,
  name,
  address,
}) => {
  return (
    <div className="relative h-auto bg-heroBg m-[5%] padding-x-box">
      <div className="container h-full w-[50vw] md:w-[100%] m-auto flex flex-col md:justify-evenly items-center gap-[20px] ">
        <div className="group cursor-pointer relative">
          <img src={image} className="w-full object-cover aspect-square" alt="" />
          {/* <div className="opacity-0 group-hover:opacity-70 absolute bg-white top-0 bottom-0 left-0 right-0 flex flex-col justify-center p-[8px]  boxHover text-center py-5">
            <p className="text-primary uppercase mb-2 text-[14px] leading-5">
              Xem dự án
            </p>
          </div> */}
        </div>

        <div>
          <strong
            className="text-center md:text-left w-full md:w-[360px] sub-text-size"
            style={{ fontSize: parseInt(14) }}
          >
            {name}
          </strong>
          <p
            className="text-center w-full mx-auto md:text-left text-primary sub-text-size text-justify"
            style={{ fontSize: parseInt(12) }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
