import React from "react";
import AboutUsItem from "./shared/AboutUsItem";
import Image1 from "./images/loi_tu_su.jpg";
import Image2 from "./images/gia_tri.jpg";
import ContactUs from "./shared/ContactUs";
import Workspace from "./shared/Workspace";

const allServices = [
  {
    id: '1',
    img: Image1,
    title: 'CÂU CHUYỆN CỦA CHÚNG TÔI BẮT ĐẦU TỪ BẠN',
    desc: `Từ những mong muốn, sở thích và phong cách riêng, Hidiarch kiến tạo nên không gian sống phản ánh “chất riêng” của bạn. Chúng tôi không chỉ thiết kế, mà còn hiện thực hóa ước mơ về một tổ ấm hoàn hảo.
    
    Sáng tạo, nhiệt huyết, chân thành, chuyên nghiệp - đó là những gì đội ngũ Hidiarch mang đến. Chúng tôi không ngừng đổi mới, kết hợp giữa thẩm mỹ tinh tế và công năng tối ưu, để mỗi công trình đều là một tác phẩm nghệ thuật độc đáo, bền vững và thân thiện với môi trường.`,
  },
  {
    id: '2',
    img: Image2,
    title: `KHỞI NGUỒN CHO Ý TƯỞNG LÀ BẠN,
      TẠO HÌNH VỀ GIÁ TRỊ LÀ CHÚNG TÔI`,
    desc: `Chúng tôi hiểu rằng niềm tin là nền tảng của mọi mối quan hệ bền vững. Đó là lý do vì sao chúng tôi đặt mục tiêu xây dựng niềm tin vững chắc với mọi khách hàng. Từ buổi tư vấn đầu tiên, chúng tôi đã bắt đầu hành trình cùng bạn, lắng nghe, thấu hiểu và đồng hành để biến mọi giấc mơ về một không gian sống hoàn hảo thành hiện thực.
    
    Bên cạnh 3 giá trị chính: Giá trị thẩm mỹ, giá trị công năng, giá trị hoàn thiện thi công, thì giá trị về niềm tin là “xương sống” để kiến tạo một văn hóa doanh nghiệp hoàn chỉnh.`,
  },
]

export default function AboutUs(props) {
  return (
    <div>
      {allServices.map((el, index) => <AboutUsItem key={el.id} item={el} index={index} />)}
      <Workspace />
      <ContactUs />
    </div>
  )
};

