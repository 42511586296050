import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import { ROUTES } from "src/router/routes";

const ContactUs = (props) => {
  const navigate = useNavigate();
  return (
    <div className={`h-auto md:max-h-[100vh] md:min-h-[50vw] mt-[30px] flex flex-col md:flex-row mx-auto relative mb-20`}>
      <div className={`md:w-1/2 flex justify-center items-center`}>
        <div className="relative">
          <img
            src={require('src/assets/images/contact_us_2.jpg')}
            id="img"
            className="w-auto h-auto md:h-[100vh] object-cover"
            alt="..."
          />
          <div className="bg-white w-full h-full absolute top-0 bg-opacity-15"></div>
        </div>
      </div>

      <div className="padding-x-box gap-[20px] mb-20 md:w-1/2 mt-5 md:mt-6 flex-col flex gap-[20px] justify-center">
        <h3 className=" text-primary title-2 md:mt-[10vh] md:text-[30px] text-[24px] whitespace-pre-line" style={{ lineHeight: 1.25 }}>
          <ScrollAnimation animateIn="animation-fadeIn">
            ĐẾN VỚI HIDIARCH, KHÁCH HÀNG SẼ NHẬN ĐƯỢC GÌ?
          </ScrollAnimation>
        </h3>
        <ScrollAnimation animateIn="md:mt-0 animate-slowBounceInLeft">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="w-full md:w-2/3 text-primary sub-text-size text-[14px] text-justify whitespace-pre-line">
          Trao đổi trực tiếp miễn phí với KTS về công năng, thẩm mỹ và các thông tin hữu ích khác ngay cả khi chưa ký hợp đồng.
        </p>
        <Button onClick={() => navigate(ROUTES.Main.Register)}>Liên hệ</Button>
      </div>
    </div>
  )
};

export default ContactUs;
