import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  faFacebookF,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { openInNewTab, open } from "src/utils";
import styled from "styled-components";
import Logo from "../../../../assets/images/LOGO-DOC-1.jpg";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useApp } from "src/hooks/useApp";
import { ROUTES } from "src/router/routes";

const ButtonComp = styled.div`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const Button = ({ icon, onClick }) => {
  return (
    <ButtonComp
      style={{
        cursor: "pointer",
        border: "solid white",
        borderWidth: 1,
        borderColor: "black",
        width: 26,
        height: 26,
        borderRadius: 13,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
      }}
      onClick={onClick}
    >
      <FontAwesomeIconAnimated icon={icon} color="white" size={"xs"} />
    </ButtonComp>
  );
};
let timer;
const DURATION = 3000;

const displayTypes = [
  { type: "map", component: Map },
  { type: "facebook", component: Facebook },
];

function Map() {
  return (
    <iframe
      title="location"
      className="mx-auto w-full h-[130px] pointer-events-none"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.963339477662!2d106.70487747583803!3d10.737308959912395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f9bb433f883%3A0x5c833e6ed77ffc61!2zNDEgxJDGsOG7nW5nIHPhu5EgNTIsIFAuIFacebookow7ogVGh14bqtbiwgUXXhuq1uIDcsIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1726078458552!5m2!1svi!2s"
      allowFullScreen=""
      loading="lazy"
    />
  );
}

// Facebook Component
function Facebook({ width }) {
  if (!width) return <></>;
  return (
    <div
      className="fb-page mx-auto pointer-events-none"
      data-href="https://www.facebook.com/profile.php?id=61565159765953"
      data-width={Math.round(width)}
      data-height={130}
      data-hide-cover="false"
      data-show-facepile="false"
    />
  );
}

const Footer = (props) => {
  const navigate = useNavigate();
  const iframeRef = useRef();
  const [iframeWidth, setIframeWidth] = useState();
  const { showModal } = useApp();

  const [activeDisplayType, setActiveDisplayType] = useState("map");
  const [hoverDisplayType, setHoverDisplayType] = useState(null); // New state to track hover

  const openPage = (page) => {
    navigate(page);
  }

  useEffect(() => {
    const postion = iframeRef.current.getBoundingClientRect();
    setIframeWidth(postion.width)
  }, [])

  // useEffect(() => {
  //   console.log('frame changed')
  //   setFbVisible(false)
  //   setTimeout(() => {
  //     const postion = iframeRef.current.getBoundingClientRect();
  //     setIframeWidth(postion.width)
  //     setFbVisible(true)
  //   }, 1000);
  // }, [isMobile, setFbVisible, setIframeWidth])

  useEffect(() => {
    timer = setInterval(function () {
      setActiveDisplayType((prevType) =>
        prevType === "map" ? "facebook" : "map"
      );
    }, DURATION);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (hoverDisplayType) {
      setActiveDisplayType(hoverDisplayType);
      clearInterval(timer);
    } else {
      clearInterval(timer);
      timer = setInterval(function () {
        setActiveDisplayType((prevType) =>
          prevType === "map" ? "facebook" : "map"
        );
      }, DURATION);
    }
  }, [hoverDisplayType]);

  const renderDisplayComponent = () => {
    return displayTypes.map((displayType) => (
      <div
        key={displayType.type}
        className={clsx(
          "relative w-full cursor-pointer",
          displayType.type === activeDisplayType && "animate-fadeInWidth"
        )}
      >
        <div
          className={clsx("absolute top-0 w-full md:w-[300px]", {
            "opacity-0": displayType.type !== activeDisplayType,
            "opacity-100": displayType.type === activeDisplayType,
          })}
        >
          <displayType.component key={iframeWidth} width={iframeWidth} />
        </div>
      </div>
    ));
  };

  const onClick = (type) => () => {
    switch (type) {
      case "phone":
        open("tel://0798897288");
        break;

      case "facebook":
        openInNewTab("https://www.facebook.com/profile.php?id=61565159765953");
        break;

      case "messenger":
        openInNewTab("https://m.me/profile.php?id=61565159765953");
        break;

      case "mail":
        open("mailto:hidiarch.studio@gmail.com");
        break;

      case "location":
        openInNewTab("https://maps.app.goo.gl/4cDemrWCk7uQBBy99");
        break;

      case "contactUs":
        navigate(ROUTES.Main.Register);
        break;

      default:
        break;
    }
  };

  return (
    <div className="border-t-primary border-t-2 flex flex-col-reverse gird lg:flex-row-reverse gap-12 lg:gap-0 p-[5%] justify-between">
      {
        <Wrapper>
          <div className="w-full md:w-[300px] max-w-[500px]">
            <div className="flex flex-row md:flex-row items-center justify-between">
              <h1 className="text-black mb-4 text-[16px] text-left font-bold">
                Liên Hệ Khác
              </h1>
              <div className="flex justify-center mb-4">
                <div
                  onMouseEnter={() => setHoverDisplayType("facebook")}
                  onMouseLeave={() => setHoverDisplayType(null)}
                >
                  <Button icon={faFacebookF} onClick={onClick("facebook")} />
                </div>
                <div
                  className="mx-2"
                  onMouseEnter={() => setHoverDisplayType("map")}
                  onMouseLeave={() => setHoverDisplayType(null)}
                >
                  <Button icon={faLocationDot} onClick={onClick("location")} />
                </div>
                <div>
                  <Button
                    icon={faFacebookMessenger}
                    onClick={onClick("messenger")}
                  />
                </div>
                <div className="ml-2">
                  <Button icon={faMoneyCheck} onClick={onClick("contactUs")} />
                </div>
              </div>
            </div>
            <div
              ref={iframeRef}
              className="h-[160px] w-full"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (activeDisplayType === "facebook") {
                  return openInNewTab(
                    "https://www.facebook.com/profile.php?id=61565159765953"
                  );
                }
                openInNewTab("https://maps.app.goo.gl/4cDemrWCk7uQBBy99");
              }}
            >
              <div onClick={(e) => {
                e.stopPropagation(); e.preventDefault();
              }}>{renderDisplayComponent(displayTypes)}</div>
            </div>
          </div>

          <div className="">
            <h1 className="text-black mb-4 text-[16px] text-left font-bold">
              Giờ Hoạt Động
            </h1>
            <div className="flex flex-col">
              <p className="text-black text-[14px]">Thứ 2 - Thứ 6:</p>
              <p className="text-black text-[14px]">9H00 đến 18H00</p>
              <p className="text-black text-[14px]">Thứ 7:</p>
              <p className="text-black text-[14px]">9H00 đến 14H00</p>
            </div>
          </div>

          <div className="">
            <h1 className="text-black font-bold mb-4 text-left text-[16px] md:w-[120px]">
              Liên Kết Nhanh
            </h1>
            <div className="flex flex-col items-start">
              <button className="text-left text-[14px] hover:text-[#CE9460]" onClick={() => openPage(ROUTES.Main.Home)}>
                Trang chủ
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]"
                onClick={() => openPage(ROUTES.Main.AboutUs)}
              >
                Về chúng tôi
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]"
                onClick={() => openPage(ROUTES.Main.AllServices)}
              >
                Dịch Vụ
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]"
                onClick={() => openPage(ROUTES.Main.Projects)}
              >
                Dự Án
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]"
                onClick={() => openPage(ROUTES.Main.Register)}
              >
                Hỗ trợ
              </button>
            </div>
          </div>
        </Wrapper>
      }
      <div className="w-full">
        <div className="flex flex-row items-center gap-2 cursor-pointer md:justify-start mb-[10px] md:mb-[10px]">
          <img
            src={Logo}
            className="w-[30px] h-[30px] border rounded-[15px]"
            alt=""
          />
          <h1 className="text-black text-[16px] font-bold md:text-left">
            Hidiarch Studio
          </h1>
        </div>
        <div className="mb-[10px]">
          <p className="text-black text-[14px] md:w-[70%] md:text-left md:leading-7">
            Công Ty TNHH Kiến Trúc Nội Thất Hidiarch
          </p>
          <p className="text-black text-[14px] md:w-[100%] md:text-left md:leading-7">
            41 Đường số 52, KDC Tân Quy Đông, P. Tân Phong, Q7, TP HCM
          </p>
        </div>
        <div className="flex md:justify-start">
          <div className="text-[14px]">
            <button onClick={onClick("phone")} className="border bg-black flex h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white md:leading-7 hover:text-black">
              Hotline 0798 897 288
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper = ({ children }) => {
  const isMobile = useMobileOrTablet();

  if (isMobile) {
    return <>{children}</>;
  } else {
    return (
      <div className="flex w-full md:flex-row-reverse justify-between">
        {children}
      </div>
    );
  }
};

export default Footer;
