import React, { createContext, useState, useContext } from 'react';

export const AppContext = createContext({});


export const AppProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  
  return (
    <AppContext.Provider value={{ modalVisible, showModal, hideModal }}>
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within an AppContext');
  }

  return context;
}
