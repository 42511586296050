// export const ROUTES_V1 = {
//   Main: {
//     Home: '',
//     AboutUs: '/v1/about-us',
//     ContactUs: '/v1/contact-us',
//     Services: '/v1/services',
//     Projects: '/v1/projects',
//     ProjectDetail: '/v1/projects/:id',
//     NotFound: '*',
//   },
//   Admin: {
//     Mail: '/admin/mail',
//     Gallery: '/admin/gallery',
//     ManageProjects: '/admin/projects',
//     ManageProjectDetail: '/admin/projects-detail',
//   }
// }


export const ROUTES = {
  Main: {
    Home: '/',
    AboutUs: '/about-us',
    ContactUs: '/contact-us',
    Register: '/contact-us/register',
    AllServices: '/all-services',
    ConstructionServices: '/construction-services',
    InteriorServices: '/interior-services',
    RenovationServices: '/renovation-services',
    OtherServices: '/other-services',
    Services: '/services',
    Projects: '/projects',
    ProjectDetail: '/project-detail',
    NotFound: '/not-found',
    Unknown: '*',
  },
  Admin: {
    Home: 'admin',
    ManageMail: 'mail',
    ManageGallery: 'gallery',
    ManageProjects: 'projects',
    ManageProjectDetail: 'projects-detail',
  }
}