import db, { storage } from '../firebase';
import { collection, getDocs, setDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { listAll, ref, getDownloadURL } from "firebase/storage";

const development = false;// !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const prefixRef = development ? 'DEV/projects/' : ''

export default function useFirebase() {

  const addProject = async (project, onFailed, onSuccess) => {
    try {
      // const ref = development ? doc(collection(db, 'DEV', project.category)) : doc(collection(db, project.category))
      const ref = doc(collection(db, prefixRef + project.category));
      await setDoc(ref, project);
      onSuccess && onSuccess();
    } catch (e) {
      console.log(e)
      onFailed && onFailed();
    }
  }

  const updateProject = async (project, id, onFailed, onSuccess) => {
    try {
      const ref = doc(db, prefixRef + `${project.category}/${id}`);
      await updateDoc(ref, project);
      onSuccess && onSuccess();
    } catch (e) {
      console.log(e)
      onFailed && onFailed();
    }
  }

  const getProjects = async (kind) => {
    try {
      let result = []
      const ref = collection(db, prefixRef + kind);
      const querySnapshot = await getDocs(ref);
      querySnapshot.forEach((doc) => {
        const { name, description, details = null, galleries, category, index, hidden = false, relatedProjects = [], mainPhotos = [] } = doc.data();
        result = [...result, { id: doc.id, name, description, details, galleries, category, index, hidden, relatedProjects, mainPhotos }]
      });
      return result.sort((a, b) => a.index - b.index);
    } catch (e) {
      return []
    }
  }

  // const getProjectDetails = async (kind, id) => {
  //   try {
  //     const ref = collection(db, prefixRef + kind + `/${id}`);
  //     const querySnapshot = await getDocs(ref);
  //     const { name, description, details = null, galleries, category, index, hidden = false, relatedProjects = [], mainPhotos = [] } = querySnapshot.data();
  //     console.log({ name, description, details, galleries, category, index, hidden, relatedProjects, mainPhotos });

  //     // querySnapshot.forEach((doc) => {
  //     //   const { name, description, details = null, galleries, category, index, hidden = false, relatedProjects = [], mainPhotos = [] } = doc.data();
  //     //   result = [...result, { id: doc.id, name, description, details, galleries, category, index, hidden, relatedProjects, mainPhotos }]
  //     // });
  //     // return result.sort((a, b) => a.index - b.index);
  //   } catch (e) {
  //     return {}
  //   }
  // }

  const updateProjectsPosition = async (projects, onFailed, onSuccess) => {
    for (let index = 0; index < projects.length; index++) {
      const project = projects[index];
      // if (project.index !== index) {
      const newProject = { ...project, index }
      await updateProject(newProject, project.id, onFailed, onSuccess)
      // }
    }
  }

  const deleteProject = async (kind, id, onFailed, onSuccess) => {
    try {
      await deleteDoc(doc(db, prefixRef + kind, id));
      onSuccess && onSuccess();
    } catch (e) {
      onFailed && onFailed();
    }
  }

  const getMails = async () => {
    try {
      let result = []
      const querySnapshot = await getDocs(collection(db, prefixRef + 'mail'));
      querySnapshot.forEach((doc) => {
        const { name, email, phoneNumber, subject, content, timestamp, status } = doc.data();
        result = [...result, { id: doc.id, name, email, phoneNumber, subject, content, timestamp, status }]
      });
      return result.sort((a, b) => b.timestamp - a.timestamp);
    } catch (e) {
      return []
    }
  }

  const addMail = async (mail, onFailed, onSuccess) => {
    try {
      const newMailRef = doc(collection(db, prefixRef + "mail"));
      await setDoc(newMailRef, mail);
      onSuccess && onSuccess();
    } catch (e) {
      onFailed && onFailed();
    }
  }

  const updateMail = async (id, newStatus, onFailed, onSuccess) => {
    try {
      const mailRef = doc(db, prefixRef + `mail/${id}`);
      await updateDoc(mailRef, {
        status: newStatus
      });
      onSuccess && onSuccess();
    } catch (e) {
      onFailed && onFailed();
    }
  }

  const deleteMail = async (id, onFailed, onSuccess) => {
    try {
      const mailRef = doc(db, prefixRef + `mail/${id}`);
      await deleteDoc(mailRef);
      onSuccess && onSuccess();
    } catch (e) {
      onFailed && onFailed();
    }
  }

  const getAllPhotos = async () => {
    const listRef = ref(storage);
    const response = await listAll(listRef);
    const itemRefs = response.items;
    const listURLs = await Promise.all(itemRefs.map(itemRef => getDownloadURL(itemRef)));
    return listURLs;
  }

  const getPhotos = async (name) => {
    let result = []
    const querySnapshot = await getDocs(collection(db, 'photos' ?? 'photos'));
    querySnapshot.forEach((doc) => {
      const { name, timestamp, url } = doc.data();
      result = [...result, { id: doc.id, url, timestamp, name }]
    });
    return result.sort((a, b) => b.timestamp - a.timestamp);
  }

  const addPhoto = async (photo) => {
    try {
      const ref = doc(collection(db, "photos"));
      await setDoc(ref, photo);
    } catch (e) {
    }
  }

  return {
    getProjects,
    addMail,
    getMails,
    updateMail,
    getAllPhotos,
    getPhotos,
    addPhoto,
    addProject,
    deleteProject,
    updateProject,
    updateProjectsPosition,
    deleteMail,
    // getProjectDetails
  }
}