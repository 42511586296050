import React from "react";
import ServiceItem from "./shared/ServiceItem";
import Image1 from './images/service1.jpg';
import Image2 from './images/service2.jpg';
import Image3 from './images/service3.jpg';
import Image4 from './images/service4.jpg';
import { useNavigate } from "react-router-dom";
import ContactUs from "./shared/ContactUs";
import { ROUTES } from "src/router/routes";

const allServices = [
  {
    id: '1',
    img: Image1,
    title: 'Kiến trúc xây dựng',
    path: ROUTES.Main.ConstructionServices,
    desc: "Cung cấp giải pháp thiết kế và thi công kiến trúc toàn diện, tham khảo những mẫu công trình độc đáo, tối ưu không gian và thẩm mỹ, phù hợp với nhu cầu của từng khách hàng.",
  },
  {
    id: '2',
    img: Image2,
    title: 'Thiết kế nội thất',
    path: ROUTES.Main.InteriorServices,
    desc: "Tổng hợp các mẫu thiết kế nội thất đẹp và những thông tin quan trọng mà gia chủ cần biết khi tìm hiểu về dịch vụ thiết kế nội thất nhà ở.",
  },
  {
    id: '3',
    img: Image3,
    title: 'Thiết kế cải tạo',
    path: ROUTES.Main.RenovationServices,
    desc: "Cung cấp cho bạn cái nhìn chi tiết và đầy đủ nhất về quy trình cải tạo, sửa chữa nhà ở. Chúng tôi sẽ giải đáp khi nào bạn nên tiến hành cải tạo ngôi nhà của mình, giới thiệu những mẫu cải tạo đáng chú ý, cũng như chia sẻ các lưu ý quan trọng.",
  },
  {
    id: '4',
    img: Image4,
    title: 'Thiết kế khác',
    path: ROUTES.Main.OtherServices,
    desc: "Xem thêm các mẫu dự án công cộng như spa, nhà hàng, khách sạn, văn phòng,...thiết kế và thi công bởi Hidiarch. Đáp ứng yêu cầu kinh doanh và mang lại trải nghiệm ấn tượng cho khách hàng.",
  }
]

export default function AllServices(props) {
  const navigate = useNavigate();
  return (
    <div>
      {allServices.map((el, index) => <ServiceItem key={el.id} item={el} index={index} onClick={() => navigate(el.path)} />)}
      {/* TODO: Phần cho FAQ */}
      {/* <div className="h:[100px] md:h-[100vh] flex items-center justify-center">Phần cho FAQ</div> */}
      <ContactUs />
    </div>
  )
};

