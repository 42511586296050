import React, { useEffect, useRef, useState } from "react";
import HeroBanner from "src/components/HeroBanner/HeroBanner";
import Welcome from "./components/Welcome";
import Service from "./components/Service";
import ContactUs from "./components/ContactUs";
import Client from "./components/Client";
import Project from "./components/Project";
import Banner from "./components/Banner";
import Outstanding from "./components/Outstanding";
import InProgressBanner from "./components/Banner/InProgressBanner";

const HomePageV2 = (props) => {
  const [showBanner, setShowBanner] = useState(false);
  const showBannerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      const isVisible = entry.isIntersecting;
      if (!showBanner && isVisible) {
        setShowBanner(isVisible);
      }
    });
    observer.observe(showBannerRef.current);
  }, []);

  return (
    <div className="relative">
      <HeroBanner />
      <Welcome />
      <Service />
      <Project />
      <Outstanding />
      <Client />
      <ContactUs />
      <div ref={showBannerRef} />
      {showBanner && <Banner />}
      <InProgressBanner />
    </div>
  );
};

export default HomePageV2;
