import React, { useRef } from "react";
import cx from "clsx";
import useClickOutside from "src/hooks/useClickOutSide";
import CloseIcon from "src/assets/images/close.svg";
import { useApp } from "src/hooks/useApp";

const InProgressBanner = (props) => {
  const ref = useRef();
  const { modalVisible, hideModal } = useApp();
  // const [playHideAnimation, setPlayHideAnimation] = useState(false);

  const handleCloseModal = () => {
    // setPlayHideAnimation(true)
    setTimeout(() => {
      hideModal();
    }, 0);
  }
  useClickOutside(ref, () => {
    handleCloseModal(false);
  });
  if (!modalVisible) return null;
  return (
    <div className="fixed top-0 z-[99999] w-full bg-black bg-opacity-70 h-full">
      <div
        ref={ref}
        className={cx("relative bg-white flex flex-col justify-end", {
          // "animate-bounceOutTop": playHideAnimation,
          "animate-bounceInTop": modalVisible,
        })}
      >
        <div
          className="absolute right-2 md:right-2 top-20 md:top-[5%] p-2 rounded-full cursor-pointer transition-all hover:scale-[120%] z-[99999]"
          onClick={handleCloseModal}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div
          className="flex flex-col gap-[10px] md:gap-[20px] p-[40px] md:p-[40px] relative"
        >
          <h3 className="text-primary md:mt-[10px] text-center md:text-left animation-delay-10 animation-fadeIn font-medium" style={{ fontSize: 18, color: 'black' }}>
            Xin lỗi vì sự bất tiện này. Website đang trong quá trình hoàn thiện.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default InProgressBanner;
