import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import LoginPage from "src/pages/admin/Login";
import LoginLayout from "./LoginLayout";
import NotFoundPage from "src/pages/NotFound";
import AdminLayout from "./AdminLayout";
import MailPage from "src/pages/admin/Mail";
import GalleryPage from "src/pages/admin/Gallery";
import ManageProjectsPage from "src/pages/admin/ManageProjects";
import ManageProjectDetailPage from "src/pages/admin/ManageProjects/ManageProjectDetail";

import LayoutV2 from "./LayoutV2/LayoutV2.js";
import HomePageV2 from "src/pages/main/V2/HomePage/HomePage.js";
import AllServicesV2 from "src/pages/main/V2/AllServices/index.js";
import AboutUsV2 from "src/pages/main/V2/AboutUs/index.js";
import ContactPageV2 from "src/pages/main/V2/ContactPage/ContactPage.js"
import ProjectsV2 from "src/pages/main/V2/Projects";
import { ROUTES } from "./routes.js";
import ProjectDetail from "src/pages/main/V2/ProjectDetail";

// import HomePage from "../pages/main/homepage/index.js";
// import Layout from "./Layout";
// import IntroductionPage from "src/pages/main/Introduction";
// import ProjectsPage from "src/pages/main/Projects";
// import ProjectsCategoryPage from "src/pages/main/Projects/ProjectCategory";
// import ProjectDetailPage from "src/pages/main/Projects/ProjectDetail";
// import ContactPage from "src/pages/main/Contact";
// import GreyLayout from "./GreyLayout.js";

// export const routerV1 = createBrowserRouter([
  // {
  //   path: "/v1",
  //   children: [
  //     {
  //       path: "",
  //       element: (
  //         <Layout>
  //           <HomePage />
  //         </Layout>
  //       ),
  //     },
  //     {
  //       path: "introduction",
  //       element: (
  //         <Layout>
  //           <IntroductionPage />
  //         </Layout>
  //       ),
  //     },
  //     {
  //       path: "contact",
  //       element: (
  //         <Layout>
  //           <ContactPage />
  //         </Layout>
  //       ),
  //     },
  //     {
  //       path: "projects",
  //       element: (
  //         <Layout>
  //           <ProjectsPage />
  //         </Layout>
  //       ),
  //     },
  //     {
  //       path: "projects/civil-construction",
  //       element: (
  //         <Layout>
  //           <ProjectsCategoryPage />
  //         </Layout>
  //       ),
  //     },
  //     {
  //       path: "projects/civil-construction/townhouse",
  //       element: (
  //         <GreyLayout>
  //           <ProjectDetailPage />
  //         </GreyLayout>
  //       ),
  //     },
  //     {
  //       path: "projects/civil-construction/apartment",
  //       element: (
  //         <GreyLayout>
  //           <ProjectDetailPage />
  //         </GreyLayout>
  //       ),
  //     },
  //     {
  //       path: "projects/public-construction",
  //       element: (
  //         <GreyLayout>
  //           <ProjectDetailPage />
  //         </GreyLayout>
  //       ),
  //     },
  //     {
  //       path: "projects/actual",
  //       element: (
  //         <GreyLayout>
  //           <ProjectDetailPage />
  //         </GreyLayout>
  //       ),
  //     },
  //   ],
  // }
// ])



const rootRouter = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: ROUTES.Main.Home,
        element: (
          <LayoutV2>
            <HomePageV2 />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.ContactUs,
        element: (
          <LayoutV2>
            <ContactPageV2 />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.Register,
        element: (
          <LayoutV2>
            <ContactPageV2 shouldScrollToForm={true} />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.AboutUs,
        element: (
          <LayoutV2>
            <AboutUsV2 />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.Projects,
        element: (
          <LayoutV2>
            <ProjectsV2 category="actual" />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.AllServices,
        element: (
          <LayoutV2>
            <AllServicesV2 />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.ConstructionServices,
        element: (
          <LayoutV2>
            <ProjectsV2 title="Kiến trúc xây dựng" category="construction" />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.InteriorServices,
        element: (
          <LayoutV2>
            <ProjectsV2 title="Thiết kế nội thất" category="interior" />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.RenovationServices,
        element: (
          <LayoutV2>
            <ProjectsV2 title="Thiết kế cải tạo" category="renovation" />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.OtherServices,
        element: (
          <LayoutV2>
            <ProjectsV2 title="Thiết kế khác" category="other" />
          </LayoutV2>
        ),
      },
      {
        path: ROUTES.Main.ProjectDetail + "/:category" + "/:id",
        element: (
          <LayoutV2>
            <ProjectDetail />
          </LayoutV2>
        ),
      },
      // {
      //   path: ROUTES.Main.NotFound,
      //   element: (
      //     <LoginLayout>
      //       <NotFoundPage />
      //     </LoginLayout>
      //   ),
      // },
      // {
      //   path: ROUTES.Main.Unknown,
      //   element: <Navigate to={ROUTES.Main.NotFound} replace />,
      // },

    ],
  },
  // admin
  {
    path: ROUTES.Admin.Home,
    children: [
      {
        path: "",
        element: (
          <LoginLayout>
            <LoginPage />
          </LoginLayout>
        ),
      },
      {
        path: ROUTES.Admin.ManageMail,
        element: (
          <AdminLayout>
            <MailPage />
          </AdminLayout>
        ),
      },
      {
        path: ROUTES.Admin.ManageGallery,
        element: (
          <AdminLayout>
            <GalleryPage />
          </AdminLayout>
        ),
      },
      {
        path: ROUTES.Admin.ManageProjects,
        element: (
          <AdminLayout>
            <ManageProjectsPage />
          </AdminLayout>
        ),
      },
      {
        path: ROUTES.Admin.ManageProjectDetail,
        element: (
          <AdminLayout>
            <ManageProjectDetailPage />
          </AdminLayout>
        ),
      }
    ],
  }
])

export default rootRouter;
