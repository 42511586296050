import "./App.css";
import React, { useEffect, useRef } from "react";
import router from "./router/RootRoute";
import { LoadingProvider } from "./hooks/useLoading";
import LoadingOverlay from "./components/LoadingOverlay";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./hooks/useAuth";
import { RouterProvider } from "react-router-dom";
import { AppProvider } from "./hooks/useApp";

import { firebaseConfig } from "./firebase";
import { initializeApp } from "firebase/app";

initializeApp(firebaseConfig);
//#endregion

const MAX_ZOOM_LEVEL = 2;

const MIN_ZOOM_LEVEL = -2;
function App() {
  const zoomLevel = useRef(0);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey || e.metaKey) {
        console.log(zoomLevel.current);
        if (e.deltaY > 0 && zoomLevel.current < MAX_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current + 1;
          return;
        } else if (e.deltaY < 0 && zoomLevel.current > MIN_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current - 1;
          return;
        }
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      console.log(zoomLevel.current);
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '=')) {
        if ((e.key === '+' || e.key === '=') && zoomLevel.current < MAX_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current + 1;
          return;
        } else if (e.key === '-' && zoomLevel.current > MIN_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current - 1;
          return;
        }
        e.preventDefault();
      }
    };

    // Add event listeners when the component mounts
    window.addEventListener('wheel', handleWheel);
    window.addEventListener('keydown', handleKeyDown);

    // Clean up by removing event listeners when the component unmounts
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1966256297172138',
        xfbml: true,
        version: 'v20.0'
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, [])

  return (
    <LoadingProvider>
      <AppProvider>
        <AuthProvider>
          <RouterProvider router={router} />
          <LoadingOverlay />
          <ToastContainer />
        </AuthProvider>
      </AppProvider>

    </LoadingProvider>
  );
}

export default App;
